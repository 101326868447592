export const baseUrl = 'https://universalportal-sso.sitcitizensbank.com';
export const assetPrefix = "/account-summary-mfe/";
export const assetURL = `${baseUrl}${assetPrefix}`;

export const environment = {
  production: false,
  isQA: false,
  isQA2: false,
  isSIT: false,
  isDEV: true,
  title: "Deposit Accounts",
  environmentFile: 'sit',
  baseBtlUrl: 'https://stargate-sit.p1.ocp.citizensbank.com/account-summary',
  transmitUrl: `${baseUrl}`,
  aesVariable: '9s8k438599284367',
  notificationsUrl: `${baseUrl}/notification/v1/notifications/viewList`,
  univ_apps_url: `${baseUrl}/auth/config/feContext`,
  univ_updateLastLoginDetails: `${baseUrl}/auth/persist/updateLastLoginDate/`,
  univ_defaultLogoutUrl: 'https://citizensbank-commercial--sit.sandbox.my.site.com/CommercialDigitalGateway/s/',
  univ_defaultMobileLogoutUrl: `${baseUrl}/sso/uportal/mobile-login`,
  univ_extendSession: `${baseUrl}/auth/extend`,
  universalLoginServiceCase: 'https://client.qacitizensbank.com/s/servicecase',
  universalLoginQuickGuide: 'https://client.qacitizensbank.com/s/article/Universal-Login',
  deeplinkCDNUrl: 'https://universalportal-sso.sitcitizensbank.com/content/deeplink/',
  enrollNowUrl: `${baseUrl}/sso/uportal/enroll`,
  univ_alive: {
    tabAliveInterval: 300000,   // millisecs - 5 minutes
    idleTimeout: 900,          // seconds - 15 minutes
    inactiveInterval: 5        // seconds
  },
  contactMessage: {
    contact1: 'Treasury Solutions specialist',
    contact2: 'Client Services',
    phone1: '877-550-5933',
    phone2: '(401-282-1362)',
    email: 'clientservices@mail.client.citizensbank.com',
    hours: '24 hours a day, 7 days a week'
  },
  univ_applications: [
    {
      name: 'accessoptima',
      displayName: 'accessOPTIMA',
      oudAppName: 'MMGPS',
      isRegistered: true,
      isTradeMarked: false,
      isActive: true,
      appLoginJson: {
        transmitJourney: 'web_access_optima',
        samlActionUrl: 'https://9471-sbx.btbanking.com/Shibboleth.sso/SAML2/POST',
        relayState: 'https://9471-tst.btbanking.com/ui/',
        logoutUrl: 'https://citizensbank-commercial--sit.sandbox.my.site.com/CommercialDigitalGateway/s/',
        mobileLogoutUrl: 'http://lwspmab00001s01.corp.internal.citizensbank.com:9661/ccp/accessoptima-mobile.jsp'
      }
    },
    {
      name: 'achieve',
      displayName: 'ACHieve Access',
      oudAppName: 'ACHIEVE',
      isRegistered: true,
      isTradeMarked: false,
      isActive: true,
      appLoginJson: {
        transmitJourney: 'web_achieve_access',
        samlActionUrl: 'https://qaachieveaccess.qacitizenscommercialbanking.com/exchange/sso/login',
        relayState: 'token'
      }
    }, {
      name: 'trade360',
      displayName: 'accessGLOBAL Trade Flow',
      oudAppName: 'GTF',
      isRegistered: true,
      isTradeMarked: false,
      isActive: true,
      appLoginJson: {
        transmitJourney: 'web_trade360'
      }
    },
    {
      name: 'seeburger',
      displayName: 'Citizens File Gateway',
      oudAppName: 'STERLING',
      isRegistered: true,
      isTradeMarked: false,
      isActive: true,
      appLoginJson: {
        transmitJourney: 'web_seeburger'
      }
    },
    {
      name: 'CASHFLOW',
      displayName: 'Cash Flow Essentials',
      oudAppName: 'CASHFLOW',
      isRegistered: false,
      isTradeMarked: true,
      isActive: true,
      appLoginJson: {
        transmitJourney: 'web_cashflow'
      }
    }
  ],
};
